.custom-calendar {
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}
.custom-calendar .react-datepicker__day-name,
.react-datepicker__header,
.react-datepicker-year-header,
.custom-calendar .react-datepicker__time-name {
	color: white;
}

.custom-calendar .react-datepicker__day--selected,
.custom-calendar .react-datepicker__day--keyboard-selected,
.react-datepicker__year-text--selected {
	background-color: black;
	color: white;
}

.custom-calendar .react-datepicker__header {
	background-color: black;
	border-bottom: 1px solid white;
}

.custom-calendar .react-datepicker__current-month {
	color: white;
}

.custom-calendar .react-datepicker__navigation--previous,
.custom-calendar .react-datepicker__navigation--next {
	border-color: white;
}
